import React from "react";
import App from "./App";
import { RecoilRoot } from "recoil";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import ErrorBoundary from "./ErrorBoundary";
import TagManager from "react-gtm-module";
import { QueryClient, QueryClientProvider } from "react-query";
import { createRoot } from "react-dom/client";
import { ReactQueryDevtools } from "react-query/devtools";
import "inticons/fonts/inticons.bundle.min.css";
import "./index.css";
import "smartbanner.js/dist/smartbanner.min.js";
import "smartbanner.js/dist/smartbanner.min.css";
import "react-toastify/dist/ReactToastify.css";
import StyledToastContainer from "component/StyledToastContainer";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 15 * 60 * 1000,
      staleTime: 0,
      retry: 0,
    },
  },
});

if (import.meta.env.VITE_APP_ARG === "prd") {
  Sentry.init({
    dsn: "https://32f4213368324bd3a81e90690a35cfa4@o491558.ingest.sentry.io/5666634",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.002,
  });

  const tagManagerArgs = {
    gtmId: "GTM-TQNF527",
  };

  TagManager.initialize(tagManagerArgs);
}

document.addEventListener("smartbanner.clickout", () => {
  (window as any).analytics.track("Button Clicked", {
    promotion_id: "smartbanner",
    creative: "",
    name: "smartbanner",
    position: "smart_app_banner",
  });
});

const AppWrapper = () => {
  return (
    <RecoilRoot>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          {!import.meta.env.PROD && <ReactQueryDevtools initialIsOpen={false} />}
          <App />
          <StyledToastContainer />
        </QueryClientProvider>
      </ErrorBoundary>
    </RecoilRoot>
  );
};

const router = createBrowserRouter([
  {
    path: "/*",
    element: <AppWrapper />,
  },
]);

createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
