import { useNavigate } from "react-router-dom";
import { isWebview, webviewPostMessage } from "utils/webview";

const OutResource = ({ children, link, style = {} }) => {
  const navigate = useNavigate();
  const isInnerUrl = (url) => {
    const host = new URL(url).host;
    console.log("host", host, url);
    return host === window.location.host;
  };

  const clickLink = (url) => {
    if (!url) return;
    if (url.startsWith("/")) {
      // 내부 링크
      navigate(url, { replace: true });
    } else if (url.includes(import.meta.env.VITE_APP_MARKET_URL)) {
      window.location.replace(url);
    } else if (isInnerUrl(url)) {
      const navigateUrl = url.split(window.location.host)[1];
      navigate(navigateUrl, { replace: true });
    } else {
      if (isWebview()) {
        const pathname = url.split("moreden.co.kr")[1];
        const isMoreden =
          url.includes("https://moreden.co.kr") ||
          url.includes("https://www.moreden.co.kr") ||
          url.includes("https://dev.moreden.co.kr") ||
          url.includes("https://www.dev.moreden.co.kr");
        !isMoreden
          ? webviewPostMessage("router:push", {
              viewType: "inAppBrowser",
              url: url,
              title: "",
              height: 0.9,
              enableRefresh: false,
            })
          : navigate(pathname);
      } else {
        window.open(url, "_blank");
      }
    }
  };

  return (
    <div onClick={() => clickLink(link)} style={style}>
      {children}
    </div>
  );
};

export default OutResource;
