import { jwtDecode } from "jwt-decode";
import { AccessToken, TokenResponse } from "types/auth";

export const getTokenWithPayload = (tokenData: TokenResponse) => {
  const { access_token = "" } = tokenData || {};
  const payload = (decodeToken(access_token) as AccessToken) || {};
  const data = {
    ...(tokenData || {}),
    uid: payload.uid,
    user_id: payload.id,
    user_name: payload.name,
    user_nickname: payload.nickname,
    user_status: payload.userStatus,
    user_type: payload.userType,
    is_owner: payload.isOwner,
    idp: payload.idp,
    customerId: payload.customerId,
  };
  return data;
};

export const decodeToken = (token: string) => {
  try {
    const decoded = jwtDecode(token);
    return decoded;
  } catch (err) {
    console.error(err);
    return null;
  }
};
