import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { tokenState } from "state";
import { redirectChicruiting } from "utils/chicruitingUtil";
import { refreshToken } from "utils/util";

const useChicruitingLogin = () => {
  const [token, setToken] = useRecoilState(tokenState);
  const isLogin = token && token.user_type;
  const [searchParam] = useSearchParams();
  const chicruitingUri = searchParam.get("chicruiting_uri");
  const navigate = useNavigate();

  const redirectLogin = () => {
    navigate({
      pathname: "/login",
      search: `?pathname=${chicruitingUri}`,
    });
  };

  const processChicruitingLogin = async () => {
    if (!chicruitingUri) {
      return;
    }

    if (!isLogin) {
      redirectLogin();
      return;
    }
    try {
      const tokenData = await refreshToken();
      if (!tokenData) {
        // refresh token이 만료된 경우
        localStorage.clear();
        sessionStorage.clear();
        setToken({});
        redirectLogin();
        return;
      } else {
        redirectChicruiting(chicruitingUri, tokenData.refresh_token, tokenData.access_token);
      }
    } catch {
      redirectLogin();
    }
  };

  return useEffect(() => {
    processChicruitingLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chicruitingUri]);
};

export default useChicruitingLogin;
