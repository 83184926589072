/* eslint-disable react-refresh/only-export-components */
import { api, ui, doLogin } from "../utils/util";

const checkUserInfo = (userInfo) => {
  return (
    userInfo &&
    userInfo.has_birthyear &&
    userInfo.has_birthday &&
    userInfo.has_gender &&
    userInfo.has_phone_number &&
    userInfo.name &&
    userInfo.phone_number.startsWith("+82")
  );
};

export const confirmKakaoToken = async (kakaoToken, pathname, setToken) => {
  window.Kakao.Auth && window.Kakao.Auth.setAccessToken(kakaoToken);
  const data = {
    token: kakaoToken,
  };

  try {
    const verifyRes = await api.post("/user/verifyKakaoToken", data);
    const verifyStatus = verifyRes.data.status;

    if (verifyStatus === "login") {
      setToken({ ...doLogin(verifyRes.data) });
      if (!verifyRes.data.user_type) {
        return Promise.resolve({ pathname: "/verifyPending" });
      }
      return Promise.resolve({ pathname: pathname });
    }

    if (verifyStatus === "sync") {
      setToken({ ...doLogin(verifyRes.data) });

      return Promise.resolve({
        pathname: "/syncAccount",
        state: {
          loginType: "kakao",
          kakaoToken: kakaoToken,
          nickname: verifyRes.data.user_nickname,
          pathname: pathname,
        },
      });
    }

    if (verifyStatus === "failed") {
      const userInfo = verifyRes.data.kakao_token.kakao_account;
      return Promise.resolve(
        checkUserInfo(userInfo)
          ? {
              pathname: "/signup",
              state: {
                kakaoToken: kakaoToken,
                loginType: "kakao",
                birthday: `${userInfo.birthyear}-${userInfo.birthday.slice(
                  0,
                  2,
                )}-${userInfo.birthday.slice(2, 4)}`,
                phone: `0${userInfo.phone_number.slice(4).replaceAll("-", "")}`,
                genderCd: userInfo.gender === "male" ? "M" : "F",
                name: userInfo.name,
              },
            }
          : {
              pathname: "/phoneVerify",
              state: {
                kakaoToken: kakaoToken,
                loginType: "kakao",
              },
            },
      );
    }
  } catch (e) {
    ui.alert(
      "치과의사 면허증의 명의 일치 여부 확인을 위해 면허증과 동일한 명의의 휴대폰 번호 인증이 필요합니다.\n휴대폰 번호가 연동된 카카오 계정으로 재시도 부탁드립니다.",
    );
    // eslint-disable-next-line no-undef
    navigate("/");
  }
};

export const kakaoLogout = () => {
  try {
    window.Kakao.Auth && window.Kakao.Auth.logout();
  } catch (e) {
    console.log("카카오 로그아웃 실패");
    ui.alert("로그아웃을 다시 시도해주세요");
  }
};
