// 전역 타입 선언
declare global {
  interface Window {
    $webview: {
      getMessage: (key: string, value: any) => void;
    };
  }
}

declare global {
  interface Window {
    webkit?: {
      messageHandlers: {
        webviewBridge: {
          postMessage: (message: any) => void;
        };
      };
    };
    webviewBridge?: {
      postMessage: (message: any) => void;
    };
  }
}

import { NavigateFunction } from "react-router-dom";

export const isMo = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isMo =
    userAgent.includes("iphone") || userAgent.includes("ipad") || userAgent.includes("android");
  return isMo;
};

export const isIOS = () => {
  const lowerCaseUserAgent = window.navigator.userAgent.toLowerCase();
  const isUserAgentIOS =
    lowerCaseUserAgent.indexOf("iphone") > -1 || lowerCaseUserAgent.indexOf("ipad") > -1;
  return isUserAgentIOS;
};

export const getVersion = () => {
  if (typeof window === "undefined") return "";

  const userAgent = window.navigator.userAgent;

  // 3.0.0 이상 버전 체크
  const newVersionMatch = userAgent.match(/Moreden\/(\d+\.\d+\.\d+)/);
  if (newVersionMatch) {
    const version = newVersionMatch[1];
    return version;
  }

  // 3.0.0 미만 버전 체크
  const oldVersionMatch = userAgent.match(/MoredenAppVersion\/(\d+\.\d+\.\d+)/);
  if (oldVersionMatch) {
    const version = oldVersionMatch[1];
    return version;
  }

  return "";
};

export const compareVersions = (v1: string, v2: string) => {
  const parts1 = v1.split(".").map(Number);
  const parts2 = v2.split(".").map(Number);

  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const part1 = parts1[i] || 0;
    const part2 = parts2[i] || 0;

    if (part1 > part2) return 1;
    if (part1 < part2) return -1;
  }

  return 0;
};

export const isWebview = () => {
  if (typeof window === "undefined") return false;

  const version = getVersion();
  if (!version) return false;

  // 3.0.0 버전 기준으로 분기
  if (compareVersions(version, "3.0.0") >= 0) {
    // 3.0.0 이상 버전
    return window.navigator.userAgent.includes("Moreden/");
  } else {
    // 3.0.0 미만 버전
    return window.navigator.userAgent.includes("MoredenAppVersion");
  }
};

export function isIOSWebview() {
  return isIOS() && isWebview();
}

export const webviewPostMessage = (bridgeName: string, value: any) => {
  if (typeof window === "undefined") return;
  if (import.meta.env.VITE_APP_ARG === "dev" || import.meta.env.VITE_APP_ARG === "local") {
    console.log("bridge::", bridgeName, value);
  }
  const _isWebview = isWebview();
  const _isIOSWebview = isIOSWebview();
  const currentAppVersion = getVersion();
  if (!_isWebview) return;

  const isLatestVersion = currentAppVersion && compareVersions(currentAppVersion, "3.0.0") >= 0;

  const toOldBridgeMessage: { [key: string]: { key: string; value: any } } = {
    openSettings: {
      key: "checkNotificationPermission",
      value: "",
    },
    checkPermission: {
      key: "checkPermission",
      value: "true",
    },
    share: {
      key: "share",
      value: {
        url: value?.data?.url || "",
        title: value?.data?.title || "",
      },
    },
    "router:push": {
      key: value?.viewType === "inAppBrowser" ? "openURL" : "router:push",
      value: value?.viewType === "inAppBrowser" ? value?.url : value,
    },
    snackBar: {
      key: "alert",
      value: value || "",
    },
    dialog: {
      key: "confirm",
      value: value || "",
    },
  };

  const jsonMessage = (() => {
    if (isLatestVersion || !toOldBridgeMessage[bridgeName]) {
      return { key: bridgeName, value };
    }
    return toOldBridgeMessage[bridgeName];
  })();

  const bridge = (() => {
    // iOS 3.0.0 이상 버전부터는 iOS Native로 전환되어, window.webkit.messageHandlers.webviewBridge 를 사용합니다.
    if (_isIOSWebview && compareVersions(currentAppVersion, "3.0.0") >= 0) {
      return window?.webkit?.messageHandlers?.webviewBridge;
    }
    return window?.webviewBridge;
  })();

  if (_isWebview && typeof bridge !== "undefined") {
    bridge.postMessage(JSON.stringify(jsonMessage));
  }
};

export const getMessage = (navigate: NavigateFunction) => {
  return (key: string, value: any) => {
    console.log("getMessage::", key, value);
    switch (key) {
      case "deepLink":
      case "notification": {
        const pathname = value.split("moreden.co.kr")[1];
        const isMoreden =
          value.includes("https://moreden.co.kr") ||
          value.includes("https://www.moreden.co.kr") ||
          value.includes("https://dev.moreden.co.kr") ||
          value.includes("https://www.dev.moreden.co.kr");
        !isMoreden ? (window.location.href = value) : navigate(pathname);
        break;
      }
      case "fcmToken":
        localStorage.setItem("fcm", value);
        break;
      default:
        break;
    }
  };
};

export const initializeBridge = (navigate: NavigateFunction) => {
  let isInitializedBridge = false;
  if (typeof window !== "undefined") {
    window.$webview = window.$webview || {};
    window.$webview.getMessage = getMessage(navigate);
  }
  if (isInitializedBridge) return;
  webviewPostMessage("initializedBridge", {});
  isInitializedBridge = true;
};
