import qs from "qs";
import { getIdToken } from "./util";
export const redirectChicruiting = (
  chicruitingUri: string,
  refreshToken: string,
  accessToken: string,
) => {
  const query = {
    refresh_token: refreshToken,
    id_token: getIdToken(),
    access_token: accessToken,
  };
  window.location.replace(`${chicruitingUri}?${qs.stringify(query)}`);
};
