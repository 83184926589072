import { lazy, Suspense } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { confirmKakaoToken } from "./component/KakaoLogin";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { setUserId } from "firebase/analytics";
import { useRecoilState } from "recoil";
import { tokenState } from "./state";
import { useEffect, useState } from "react";
import { api, doLogin } from "./utils/util";
import analytics from "./firebase";
import PageTracker from "./component/PageTracker";
import { isMarketOnlyUser } from "utils/marketUtil";
const CheckCI = lazy(() => import("component/CheckCI"));
const Loading = lazy(() => import("./component/loading"));
const Home = lazy(() => import("./tab/Home"));
const Board = lazy(() => import("./tab/board"));
const Qna = lazy(() => import("./tab/qna"));
const Logout = lazy(() => import("./component/logout"));
const Management = lazy(() => import("./tab/management"));
const Alarm = lazy(() => import("./tab/alarm"));
const Verify = lazy(() => import("./tab/verify"));
const Festival = lazy(() => import("./tab/festival"));
const DentalLab = lazy(() => import("./tab/dentalLab"));
const Forum = lazy(() => import("./tab/Forum"));
const Signup = lazy(() => import("./tab/Signup"));
const PhoneVerify = lazy(() => import("./tab/phoneverify"));
const Recommender = lazy(() => import("./tab/Recommender"));
const FindId = lazy(() => import("./tab/findid"));
const FindPw = lazy(() => import("./tab/findpw"));
const Contents = lazy(() => import("./tab/contents"));
const AboutUs = lazy(() => import("./component/about_us"));
const PrivacyPolicy = lazy(() => import("./component/privacy_policy"));
const TermsOfUse = lazy(() => import("./component/terms_of_use"));
const RequestSignup = lazy(() => import("./component/request_signup"));
const Class = lazy(() => import("./tab/class"));
const Login = lazy(() => import("./tab/Login"));
const SurveyPage = lazy(() => import("./tab/SurveyPage"));
const SyncAccount = lazy(() => import("./component/sync_account"));
const ExpoRoute = lazy(() => import("./tab/ExpoRoute"));
const TongTestRoute = lazy(() => import("./tab/TongTestRoute"));
const MyPageRoute = lazy(() => import("./tab/MypageRoute"));
const RecruitRoute = lazy(() => import("./tab/recruit/RecruitRoute"));
const LabPage = lazy(() => import("./tab/LabPage"));
const LoanPage = lazy(() => import("./tab/LoanPage"));
const ConsultingPage = lazy(() => import("./tab/ConsultingPage"));
const ProfilePage = lazy(() => import("./tab/recruit/ProfilePage"));
const KlassRoute = lazy(() => import("./tab/moreden_class/KlassRoute"));
const ForDeveloper = lazy(() => import("./tab/ForDeveloper"));
const Chicruiting = lazy(() => import("./tab/chicruiting/Chicruiting"));
const UnloginChicruiting = lazy(() => import("./tab/chicruiting/UnloginChicruiting"));
const Auth = lazy(() => import("./tab/auth"));
const SearchPage = lazy(() => import("./component/SearchPage"));
const PortalRoot = lazy(() => import("./component/PortalRoot"));
const MoredenAi = lazy(() => import("./tab/ai/MoredenAi"));
import AuthChicruitingCallback from "./tab/AuthChicruitingCallback";
import { initializeBridge, isWebview } from "utils/webview";

SwiperCore.use([Navigation, Pagination, Autoplay]);

export const CLASS_ROUTE = "class";
export const EDU_ROUTE = "edu";

const MarketOnlyRoutes = (kakaoToken) => (
  <>
    <Suspense fallback={isWebview() ? <></> : <Loading />}>
      <Routes>
        <Route element={<PageTracker />}>
          <Route path="/footer/business" element={<AboutUs />} />
          <Route path="/footer/service" element={<TermsOfUse />} />
          <Route path="/footer/privacy" element={<PrivacyPolicy />} />
          <Route path="/logout/*" element={<Logout />} />
          <Route path="/auth/*" element={<Auth />} />
          <Route path="/" element={<Home />} />
          <Route path="/forDeveloper" element={<ForDeveloper kakaoToken={kakaoToken} />} />
          <Route path="*" element={<RequestSignup />} />
        </Route>
      </Routes>
    </Suspense>
    <PortalRoot />
  </>
);

const CommunityRoutes = (kakaoToken) => (
  <>
    <Suspense fallback={isWebview() ? <></> : <Loading />}>
      <Routes>
        <Route element={<PageTracker />}>
          <Route element={<CheckCI />}>
            <Route path="/recruit/*" element={<RecruitRoute />} />
            <Route path="/chicruiting/*" element={<Chicruiting />} />
            <Route path="/survey/:sid/*" element={<SurveyPage />} />
            <Route path={`/${EDU_ROUTE}/*`} element={<Class />} />
            {/* 클래스 */}
            <Route path={`/${CLASS_ROUTE}/*`} element={<KlassRoute />} />
            <Route path="/post/*" element={<Board />} />
            <Route path="/qna/*" element={<Qna />} />
            <Route path="/forum/*" element={<Forum />} />
            <Route path="/ai/*" element={<MoredenAi />} />
            <Route path="/tongtest/*" element={<TongTestRoute />} />
            <Route path="/expo/*" element={<ExpoRoute />} />
            <Route path="/dentalLab/*" element={<DentalLab />} />
            <Route path="/management/*" element={<Management />} />
            <Route path="/festival/*" element={<Festival />} />
            <Route path="/alarm/*" element={<Alarm />} />
            <Route path="/contents/*" element={<Contents />} />
            <Route path="/profile/:uid" element={<ProfilePage />} />
            <Route path="/mypage/*" element={<MyPageRoute />} />
            <Route path="/footer/business" element={<AboutUs />} />
            <Route path="/footer/service" element={<TermsOfUse />} />
            <Route path="/footer/privacy" element={<PrivacyPolicy />} />
            <Route path="/syncAccount/*" element={<SyncAccount />} />
            <Route path="/search/*" element={<SearchPage />} />
            <Route path="/auth/chicruiting/callback" element={<AuthChicruitingCallback />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="/forDeveloper" element={<ForDeveloper kakaoToken={kakaoToken} />} />
          </Route>
          <Route path="/logout/*" element={<Logout />} />
        </Route>
      </Routes>
    </Suspense>
    <PortalRoot />
  </>
);

const PublicRoutes = (kakaoToken) => (
  <>
    <Suspense fallback={isWebview() ? <></> : <Loading />}>
      <Routes>
        <Route element={<PageTracker />}>
          <Route path="/recruit/*" element={<RecruitRoute />} />
          <Route path="/chicruiting/*" element={<UnloginChicruiting />} />
          <Route path={`/${CLASS_ROUTE}/*`} element={<KlassRoute />} />
          <Route path="/contents/*" element={<Contents />} />
          <Route path="/festival/*" element={<Festival />} />
          <Route path="/expo/*" element={<ExpoRoute />} />
          <Route path="/tongtest/*" element={<TongTestRoute />} />
          <Route path="/management/*" element={<Management />} />
          <Route path="/management/lab" element={<LabPage />} />
          <Route path="/management/loan" element={<LoanPage />} />
          <Route path="/management/consulting" element={<ConsultingPage />} />
          <Route path="/login/*" element={<Login />} />
          <Route path="/signup/*" element={<Signup />} />
          <Route path="/phoneVerify/*" element={<PhoneVerify />} />
          <Route path="/recommender/*" element={<Recommender />} />
          <Route path="/findid/*" element={<FindId />} />
          <Route path="/findpw/*" element={<FindPw />} />
          <Route path="/footer/business" element={<AboutUs />} />
          <Route path="/footer/service" element={<TermsOfUse />} />
          <Route path="/footer/privacy" element={<PrivacyPolicy />} />
          <Route path="/syncAccount/*" element={<SyncAccount />} />
          <Route path="/verifyPending/*" element={<Verify />} />
          <Route path="/logout/*" element={<Logout />} />
          <Route path="/auth/*" element={<Auth />} />
          <Route path="/auth/chicruiting/callback" element={<AuthChicruitingCallback />} />
          <Route path="/" element={<Home />} />
          <Route path="/forDeveloper" element={<ForDeveloper kakaoToken={kakaoToken} />} />
          <Route path="*" element={<RequestSignup />} />
        </Route>
      </Routes>
    </Suspense>
    <PortalRoot />
  </>
);

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useRecoilState(tokenState);
  const searchParam = new URLSearchParams(location.search);
  const rcode = searchParam.get("rcode");
  const refresh_token = searchParam.get("refresh_token"); // template_token 이 존재한다는 것은, 디바이스 로그인으로 봐야 함
  const kakao_token = searchParam.get("kakao_token");
  const fcm_token = searchParam.get("fcm_token");
  const [testKakaoValue, setTestKakaoValue] = useState("");

  analytics && token && token.uid && setUserId(analytics, token.uid);

  if (rcode && !localStorage.getItem("rcode")) {
    localStorage.setItem("rcode", rcode);
  }

  useEffect(() => {
    initializeBridge(navigate);
  }, []);

  // iamport 초기화
  useEffect(() => {
    const IMP = window.IMP;
    if (!IMP) return;
    IMP.init("imp60774027");
  }, []);

  // FIXME mobile app에서 token 던져주는 시나리오에서 코드. 개선 필요
  useEffect(() => {
    if (fcm_token) {
      localStorage.setItem("fcm", fcm_token);
    }

    if (kakao_token) {
      setTestKakaoValue(kakao_token);

      (async () => {
        const { pathname, state } = await confirmKakaoToken(
          kakao_token,
          location.pathname,
          setToken,
        );
        navigate(pathname, { state: state, replace: true });
      })();
      return;
    }

    if (refresh_token) {
      localStorage.setItem("token", JSON.stringify({ refresh_token: refresh_token }));
      api
        .get("/user/token")
        .then((res) => {
          const res_token = {
            ...doLogin(res.data),
            access_token: api.defaults.headers.Authorization.split("Bearer ")[1],
            refresh_token,
          };
          localStorage.setItem("token", JSON.stringify(res_token));
          setToken(res_token);
        })
        .catch(() => {
          api.defaults.headers.Authorization = ``;
          navigate("/logout");
        });

      return;
    }
  }, [fcm_token, kakao_token, location.pathname, navigate, refresh_token, setToken]); // ERR FIX

  // 모어덴 마켓 전용 계정
  if (token && token.user_type && isMarketOnlyUser(token.user_type)) {
    return <MarketOnlyRoutes kakaoToken={testKakaoValue} />;
  }

  // 커뮤니티 계정
  if (token && token.uid && token.user_type) {
    return <CommunityRoutes kakaoToken={testKakaoValue} />;
  }

  return <PublicRoutes kakaoToken={testKakaoValue} />;
};

export default App;
